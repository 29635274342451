<template>
	<div class="page seriesList_page">
		<div class="bannerbox animated">
			<el-carousel indicator-position="none" arrow="never" :autoplay="false" :height="screenHeight+'px'">
			    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
			       <div class="imgbox">
					   <!-- :poster="item.imageUrl" -->
					    <video v-show="item.videoUrl" :src="item.videoUrl" 
						class="video img100"
						id="video"
						ref="videoPlayer"
						style="object-fit: cover;"
						:loop = "true"
						:autoplay="true"
						muted
						playsinline x5-playsinline webkit-playsinline x5-video-player-fullscreen></video>
					    <img v-if="!item.videoUrl" class="img100" :src="item.imageUrl"/>
						<div class="titlebox animation_inUp">
						   <div class="text1 fontsize68" v-if="item.title">{{item.title}}</div>
						   <div class="text2 fontsize36" v-if="item.subtitle">{{item.subtitle}}</div>
						   <!-- <div v-if="item.redirectUrl.trim()" class="navicon" @click="handleSearche(item.redirectUrl)"><img class="img100" src="../assets/static/navto.png"/></div> -->
					    </div>
					</div>
			    </el-carousel-item>
			</el-carousel>
		</div>
		<!-- 内容1 -->
		<div class="data_con1">
			<div class="w100 relative" v-for="(item,index) in dataList" :key="index" @click="handledetail(item.id)">
				<div class="bg1" v-if="(index+1) % 3==1"></div>
				<div class="bg2" v-if="(index+1) % 3==2"></div>
				<div class="bg3" v-if="(index+1) % 3==0"></div>
				<div class="box1 w1200 relative">
					<div class="imgbox">
						<img class="img100 hoverbig" :src="item.imageUrl"/>
					</div>
					<div class="conbox" v-if="(index+1) % 2==1">
						<div class="leftbox">
							<div class="text1 fontsize32" v-if="item.subtitle">{{item.subtitle}}</div>
							<div class="text1 fontsize32">{{item.title}}</div>
							<div class="text2 fontsize16">{{item.description}}</div>
						</div>
						<div class="rightbox">
							<div class="text1 fontsize16">Leam more</div>
							<img class="img" src="../assets/static/Frame283.png"/>
						</div>
					</div>
					<div class="conbox conbox2" v-if="(index+1) % 2==0">
						<div class="leftbox">
							<div class="text1 fontsize32" v-if="item.subtitle">{{item.subtitle}}</div>
							<div class="text1 fontsize32">{{item.title}}</div>
							<div class="text2 fontsize16">{{item.description}}</div>
						</div>
						<div class="rightbox">
							<div class="text1 fontsize16">Leam more</div>
							<img class="img" src="../assets/static/Frame283.png"/>
						</div>
					</div>
				</div>
			</div>	
		</div>
		
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			screenHeight:1080,//默认
			bannerList: [],
			dataList: [],//内容
		};
	},
	mounted: function() {
		this.screenHeight = window.innerHeight;
		//获取
		this.initFuc()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	activated() {
	    console.log('Component A activated');
		var _this = this
		
	},
	methods: {
		//初始化
		async initFuc(){
			await this.getwheelPage(1,"content/ct-wheel-advert/indexHeader/seriesHeader")
			await this.getwheelPage(2,"content/ct-series-info/seriesInfo")
		},
		//文章详情
		handledetail(uid){
			this.$router.push({ path: '/seriesDetail', query: {uid: uid }})
		},
		//获取轮播图
		getwheelPage(type,conurl) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 18
				params["type"] = type
				this.$http.post(conurl, params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							if(type==1){
								_this.bannerList = records
							}else if(type==2){
								records.forEach((item,index)=>{
									if (item.imgUrlList) {
										try {
											item["imgUrlList"] = JSON.parse(item.imgUrlList)
											item["imageUrl"] = item.imgUrlList[0]
										} catch (e) {
											item["imgUrlList"] = []
											item["imageUrl"] = ""
											// TODO handle the exception
										}
									}
								})
								_this.dataList = records
							}else{
								
							}
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
	}
};
</script>
<style lang="scss" scoped>
	.hoverbig:hover{
	    transform: scale(1.01);
	    transition: ease all 0.3s;
	}
</style>
